import React, { useContext, useEffect, useState } from 'react'
import { Box, Card, Divider, RoundedPlainTextButton, Typography, makeAppStyles } from '@percept/mui'
import { pathwaysDataByIsoCode, pathwaysDatasets, pathwaysMarkets } from './graphData'
import { ChevronRightThin, CloudDownload } from '@percept/mui/icons'
import { PathwaysJourney } from './PathwaysJourney'
import { isVodafoneGlobalUser } from 'components/MediaMixModelling'
import { vodafoneMarkets } from 'vodafoneMarkets'
import { marketPathwaysAssetMap } from './pathwaysAssets'
import { AssetLink } from 'components/AssetLink'
import { percentageFormatter, ResponsiveStackedHistogramWithTooltip } from '@percept/mui/charts'
import { identity } from 'lodash-es'
import { MarketSelect } from 'components/MarketDisplay'
import { UserPrivilegeContext } from '@percept/app-components'


const useStyles = makeAppStyles( theme => ({
  container: {
    padding: theme.spacing(6, 6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 6),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    fontSize: 38,
    lineHeight: 1.125,
    fontWeight: 700,
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: 18,
  },
  tabs: {
    margin: theme.spacing(4,0),
  },
  tab: {
    color: theme.palette.common.black,
  },
  card: {
    margin: theme.spacing(6, 0),
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  titleSeparatorIcon: {
    margin: theme.spacing(0, 1),
    fontSize: '0.85em',
    color: 'rgba(0, 0, 0, 0.5)',
    strokeWidth: 2,
  },
  downloadLink: {
    marginRight: theme.spacing(3),
  },
}))


export const Pathways = (): JSX.Element => {
  const classes = useStyles()

  const [marketId, setMarketId] = useState<string | null>(null)

  const userPrivileges = useContext(UserPrivilegeContext)
  
  const defaultMarketId = userPrivileges.user && userPrivileges.user.default_org_unit_id
  
  const isGlobalUser = defaultMarketId && isVodafoneGlobalUser(defaultMarketId)

  useEffect(() => {
    if( defaultMarketId && !marketId ){
      if( isGlobalUser ){
        setMarketId(vodafoneMarkets[0].id)
      }else{
        setMarketId(defaultMarketId)
      }
    }
  }, [defaultMarketId, isGlobalUser, marketId])

  const market = pathwaysMarkets.find( m => m.id === marketId )

  const data = market && pathwaysDataByIsoCode[market.iso_code]

  const downloadAssetIdentifier = market && marketPathwaysAssetMap[market.iso_code]

  return (
    <Box className={classes.container}>
      <Typography variant='h3' className={classes.title}>
        Pathways
        <ChevronRightThin className={classes.titleSeparatorIcon} />
        The Customer Journey
      </Typography>

      <Typography className={classes.text} paragraph>
        Pathways is a consumer experience model evaluating Vodafone&apos;s relationship with the household - uncovering what they are doing, 
        thinking, feeling and experiencing, at each stage of the purchase journey (exploration, experience, loyalty, passive).
      </Typography>

      <Typography className={classes.text} paragraph>
        This insight into households needs enables the right proposition or message to be delivered and communicated in the most relevant and
        inspiring manner,  via the right touchpoint to build lasting brand equity and affinity. 
        The study (field work spring 2024) has been deployed across all VF markets being able to drill down on Mobile, Fixed and Converged segments.
      </Typography>
          
      <Card className={classes.card} elevation={2}>
        <Box display='flex' alignItems='center' justifyContent='space-between' m={1.5}>
          <Typography variant='h4'>Customer Journey Profile</Typography>
          <Box display='flex' alignItems='center'>
            { downloadAssetIdentifier && (
              <AssetLink
                linkContent={
                  <RoundedPlainTextButton
                    variant='contained'
                    startIcon={<CloudDownload />}>
                    Download Report
                  </RoundedPlainTextButton>
                }
                showIcon={false}
                className={classes.downloadLink}
                assetIdentifier={downloadAssetIdentifier} />
            )}
            { market && (
              isGlobalUser ? (
                <MarketSelect
                  value={market.id}
                  options={pathwaysMarkets}
                  onChange={(selectedMarket): void => {
                    setMarketId(selectedMarket.id)
                  }} />
              ) : (
                <Typography variant='h4'>{market.name}</Typography>
              )
            )}
          </Box>
        </Box>

        <Divider />

        { data && <PathwaysJourney data={data} /> }

      </Card>

      <Card className={classes.card} elevation={2}>
        <Box m={1.5}>
          <Typography className={classes.flexCenter} variant='h4'>
            Customer Journey Profiles
            <ChevronRightThin className={classes.titleSeparatorIcon} />
            All Markets
          </Typography>
        </Box>
        <Divider />
        <Box p={3}>
          <ResponsiveStackedHistogramWithTooltip
            height={400}
            axisText
            grid='rows'
            barPaddingRatio={0.2}
            domain={[0, 100]}
            xTickFormatter={identity}
            yTickFormatter={percentageFormatter}
            datasets={pathwaysDatasets} />
        </Box>
      </Card>

      <Card className={classes.card} elevation={2}>
        <Box m={1.5}>
          <Typography className={classes.flexCenter} variant='h4'>Key</Typography>
        </Box>

        <Divider />

        <Box p={3}>
          <Typography>Our updated definitions include households and are not bound to time constraints as previously</Typography>
          <br />
          <Typography variant='h6'>Passive stage</Typography>
          <Typography>Mobile Network OR Broadband provider does not meet needs: Not meeting my needs at all to neutral (1-3) AND also including those who say “I don&apos;t know”</Typography>
          <br />
          <Typography variant='h6'>Exploration</Typography>
          <Typography>Very likely or likely to change mobile Network OR Broadband provider + Decision Maker + Have begun looking at options</Typography>
          <br />
          <Typography variant='h6'>Experience</Typography>
          <Typography>Changed mobile Network OR Broadband provider one month ago</Typography>
          <br />
          <Typography variant='h6'>Loyalty</Typography>
          <Typography>Mobile Network AND Broadband provider does meet needs (strongly or slightly) (4-5)</Typography>
        </Box>
      </Card>
      
    </Box>
  )
}