import React, { useContext } from 'react'

import { Box, Typography, makeAppStyles } from '@percept/mui'
import { MMMMarket } from './Market'
import { MMMAllMarkets } from './Markets'

import { UserPrivilegeContext } from '@percept/app-components'
import { VODAFONE_GLOBAL_ID } from 'vodafoneMarkets'

const typography = {
  title: 'Media Mix Modelling',
  descriptions: [
    <>
      A media mix model (MMM) is a statistical and analytical tool used to determine the most effective
      allocation of advertising resources across various media channels such as television, radio, print, digital,
      and social media. It aims to optimize the distribution of budget to maximize the impact of media efforts.
      By analyzing historical data, a media mix model helps to make data-driven decisions to enhance our overall
      media and marketing strategies.
    </>,
    <>
      We deployed a central MMM based on monthly data (media data as well as commercial data).
      The figures shown representing the analysis from October 2024 and include data from April 2018 till March 2024.
    </>
  ]
}

const useStyles = makeAppStyles( theme => ({
  container: {
    padding: theme.spacing(6, 6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 6),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    fontSize: 38,
    lineHeight: 1.125,
    fontWeight: 700,
    marginBottom: theme.spacing(4),
  },
  text: {
    fontSize: 18,
  },
  tabs: {
    margin: theme.spacing(4, 0),
    borderBottom: `1px solid ${theme.palette.text.hint}`,
  },
  tab: {
    textTransform: 'none',
  },
}))


export const isVodafoneGlobalUser = (defaultPlatformUnitId: string): boolean => (
  defaultPlatformUnitId === VODAFONE_GLOBAL_ID
)

export const MediaMixModelling = (): JSX.Element => {
  const classes = useStyles()

  const userPrivileges = useContext(UserPrivilegeContext)

  const defaultMarketId = userPrivileges.user && userPrivileges.user.default_org_unit_id

  const isGlobalUser = isVodafoneGlobalUser(defaultMarketId)

  return (
    <Box className={classes.container}>
      <Typography variant='h3' className={classes.title}>
        {typography.title}
      </Typography>
      { typography.descriptions.map( (text, i) => (
        <Typography key={i} paragraph className={classes.text}>{text}</Typography>
      ))}

      <Box mt={4}>
        <MMMMarket
          defaultMarketId={defaultMarketId}
          isGlobalUser={isGlobalUser} />
        <Box mt={4}>
          <MMMAllMarkets />
        </Box>
      </Box>
    </Box>
  )
}
