import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import {
  Box,
  Card,
  Loader,
  MultiProviderSelect,
  PercentageDonut,
  ProviderLogo,
  RoundedPlainTextButton,
  RoundedPlainTextButtonMenu,
  Typography,
  makeAppStyles,
} from '@percept/mui'

import { ArrowDropDown, CloudDownload, ErrorOutline } from '@percept/mui/icons'

import { UserPrivilegeContext } from '@percept/app-components'

import {
  WastageComparisonType,
  WastageParams,
  WastageProvider,
  WastageSegmentation,
  WastageVariant,
  useCombinedWastage,
  useCreativeWastage,
  useDigitalWastage,
  useTotalCreativeWastage,
  useWastageReferenceDates,
  useWastageTrendExport,
} from '@percept/hooks'

import {
  ControlBox,
  StateHookCheckbox,
  WastageSegmentationSelect,
  WastageTrendTabs,
} from './WastageTrendComponents'

import { MonthRangeSelect, MultiMarketSelect } from 'components/Selects'

import { MarketDisplayLabel } from 'components/MarketDisplay'

import { ParentSize } from '@visx/responsive'

import { WastageTrendChart } from './WastageTrendChart'

import { WastageTrendTable } from './WastageTrendTable'

import {
  ComparisonMethod,
  deslugify,
  downloadSVG,
  getFinancialYearStart,
  getPercentage,
  userHasOrgPrivileges,
} from '@percept/utils'

import { getDatasetTotal } from '@percept/mui/charts/lib'

import {
  datasetColorSchemesMapping,
  deriveRequiredComparisonPeriod,
  formatMonth,
  getAllowableDateRange,
  getCreativeQualityScoreDataset,
  getDerivedTotalDataset,
  getSpendDatasets,
  getWastagePercentageDataset,
  providerOrder,
  supportedComparisonTypeMapping,
  vodafoneMarketsById,
} from './dataUtils'

import { VODAFONE_GLOBAL_ID, VodafoneMarket, vodafoneMarkets } from 'vodafoneMarkets'

import { get, intersection, some } from 'lodash-es'

import { endOfMonth, startOfMonth } from 'date-fns'

import { UseQueryResult } from 'react-query'

import { SpendDataset } from './typings'

const { WORKLOAD_TYPE } = process.env

const useStyles = makeAppStyles( theme => ({
  title: {
    fontSize: 38,
    lineHeight: 1.125,
    fontWeight: 700,
    marginBottom: theme.spacing(4),
  },
  text: {
    fontSize: 16,
    lineHeight: 1.35,
    maxWidth: '71em',
  },
  tabs: {
    margin: theme.spacing(4,0),
    borderBottom: `1px solid ${theme.palette.text.hint}`,
  },
  tab: {
    textTransform: 'none',
  },
  spacedRight: {
    marginRight: theme.spacing(2),
  },
  derivedTotalDatasetText: {
    transition: theme.transitions.create('color'),
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
  separator: {
    margin: theme.spacing(0, 1.5),
    color: theme.palette.text.disabled,
  },
  filterItem: {
    marginRight: theme.spacing(1.5),
    ':last-child': {
      marginRight: 0,
    },
  },
}))


const providerOptionsByWastageVariant: Record<WastageVariant, WastageProvider[]> = {
  COMBINED: [
    'GOOGLE_ADS', 'FACEBOOK', 'DV360', 'ADFORM'
  ],
  DIGITAL: [
    'GOOGLE_ADS', 'FACEBOOK', 'DV360', 'ADFORM'
  ],
  CREATIVE: [
    'GOOGLE_ADS', 'FACEBOOK', 'DV360', 'ADFORM', 'TIKTOK'
  ]
}


const variantDescriptions: Record<WastageVariant, React.ReactNode> = {
  COMBINED: <>
    Ad investment lost due to not following media and creative best practices. It's taken from the
    Media Quality Dashboard and CreativeX, which identify where media and creative best practices
    are not followed on Meta, GoogleAds, Youtube, DV360, TikTok and Adform, leading to wastage.
    Platform algorithms will deprioritise ads that don't follow best practices, which has a direct impact on cost.
  </>,
  DIGITAL: <>
    Ad investment lost by not following media-specific best practices. Platform algorithms will deprioritise ads
    that don't follow best practices in structure, formats, audience and brand safety, which has a direct impact on cost.
    This is calculated via the Media Quality Dashboard (Percept), assessing each best practice's efficiency impact separately.
  </>,
  CREATIVE: <>
    Ad investment lost by not following creative-specific best practices. Platform algorithms will deprioritise ads that
    don't follow best practices such as simple message, correct length, aspect ratio, sound on/off, amongst others which
    has a direct impact on cost. This is calculated via CreativeX, whereby for each 10 pp Creative Quality Score (CQS) below
    our Vodafone goal of 80%, CPM increases 2%, resulting in wastage from increased costs. This is backed by robust research
    from CreativeX across their advertisers and confirmed by a regression analysis with Vodafone market data.
  </>,
}


const isQueryHookLoading = (hookValue: UseQueryResult): boolean => (
  hookValue.isLoading || (!hookValue.data && !hookValue.isError)
)

const isQueryHookError = (hookValue: UseQueryResult): boolean => (
  !hookValue.data && hookValue.isError
)


const getMarketFilterLabel = (
  markets: string[] | null,
): React.ReactNode => {
  if( !markets || !markets.length || markets.length === vodafoneMarkets.length ){
    return <Typography variant='h5'>All Markets</Typography>
  }
  const selectedMarkets = markets.map( id => vodafoneMarketsById[id] )
  return <>
    { selectedMarkets.map( (m, i) => (
      <span
        key={m.id}
        style={{
          fontSize: 14,
          fontWeight: 600,
          marginRight: i === selectedMarkets.length - 1 ? 0 : 12,
        }}>
        <MarketDisplayLabel
          key={m.id}
          {...m} />
      </span>
    ))}
  </>
}

const getVendorFilterLabel = (
  vendors: WastageProvider[] | null,
  supportedVendors: WastageProvider[]
): React.ReactNode => {
  if( !vendors || !vendors.length ){
    vendors = supportedVendors
  }
  return <>
    { intersection(providerOrder, vendors).map( provider => (
      <span key={provider} style={{marginRight: 12}}>
        <ProviderLogo
          provider={provider}
          size={1.65}
          style={{
            position: 'relative',
            top: 3,
          }} />
      </span>
    ))}
  </>
}

export const WastageTrendReports = (): JSX.Element => {

  const privileges = useContext(UserPrivilegeContext)

  const [wastageVariant, setWastageVariant] = useState<WastageVariant>('COMBINED')

  const [wastageSegmentation, setWastageSegmentation] = useState<WastageSegmentation>('PROVIDER')

  const cqsToggle = useState<boolean>(true)

  const wastagePercentageToggle = useState<boolean>(true)

  const wastageSpendToggle = useState<boolean>(true)

  const defaultOrgUnitId = privileges.user && privileges.user.default_org_unit_id

  const org_unit_id = VODAFONE_GLOBAL_ID

  const wastageReferenceDates = useWastageReferenceDates({
    org_unit_id,
  })

  const userCanExport = userHasOrgPrivileges(
    ['vfWastageTrendReports.export'],
    VODAFONE_GLOBAL_ID,
    privileges.org_privileges,
  )

  const csvExportHook = useWastageTrendExport()

  const { minDate, maxDate } = getAllowableDateRange({
    minDate: get(wastageReferenceDates.data, 'start', null),
    maxDate: get(wastageReferenceDates.data, 'end', null),
  })

  const [orgUnitIdFilter, setOrgUnitIdFilter] = useState<string[]>(() => (
    defaultOrgUnitId === VODAFONE_GLOBAL_ID ?
      vodafoneMarkets.map( m => m.id ) :
      [defaultOrgUnitId]
  ))

  const marketOptions: VodafoneMarket[] = vodafoneMarkets.filter( m => (
    userHasOrgPrivileges(['structuralReporting.report.view'], m.id, privileges.org_privileges)
  ))

  const [providerFilter, setProviderFilter] = useState<WastageProvider[]>(
    providerOptionsByWastageVariant[wastageVariant]
  )

  useEffect(() => {
    const options = providerOptionsByWastageVariant[wastageVariant]
    if( intersection(providerFilter, options).length < providerFilter.length ){
      console.warn('Invalid options')
      setProviderFilter(options)
    }
  }, [wastageVariant, providerFilter])

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null])

  useEffect(() => {
    if( dateRange[0] === null && dateRange[1] === null && wastageReferenceDates.data ){
      if( WORKLOAD_TYPE === 'DEV' ){
        setDateRange([
          new Date('2023-04-01'),
          new Date('2023-06-30'),
        ])
      }else{
        const { maxDate } = getAllowableDateRange({
          minDate: wastageReferenceDates.data.start,
          maxDate: wastageReferenceDates.data.end,
        })
        setDateRange([
          getFinancialYearStart(maxDate),
          maxDate,
        ])
      }
    }
  }, [wastageReferenceDates.data, dateRange])

  const [comparisonType, setComparisonType] = useState<WastageComparisonType | null>(null) 

  const comparisonDateRange = deriveRequiredComparisonPeriod(
    dateRange,
    comparisonType,
    wastageSegmentation,
  )

  const [comparisonMethod, setComparisonMethod] = useState<ComparisonMethod>('RELATIVE')

  const baseWastageHookProps: Omit<WastageParams, 'start' | 'end'> = {
    org_unit_id,
    segment_type: wastageSegmentation,
    org_unit_ids: orgUnitIdFilter,
    providers: providerFilter,
  }

  const wastageHookProps: WastageParams = {
    ...baseWastageHookProps,
    start: dateRange[0],
    end: dateRange[1],
  }

  const digitalWastageHook = useDigitalWastage({
    ...wastageHookProps,
    enabled: wastageVariant === 'DIGITAL',
  })

  const combinedWastageHook = useCombinedWastage({
    ...wastageHookProps,
    enabled: wastageVariant === 'COMBINED',
  })

  const creativeWastageHook = useCreativeWastage({
    ...wastageHookProps,
    enabled: true,
  })

  const totalCreativeWastageHook = useTotalCreativeWastage({
    ...wastageHookProps,
    enabled: true,
  })

  const wastageComparisonHookProps: WastageParams = {
    ...baseWastageHookProps,
    start: comparisonDateRange[0],
    end: comparisonDateRange[1],
  }

  const digitalWastageComparisonHook = useDigitalWastage({
    ...wastageComparisonHookProps,
    enabled: wastageVariant === 'DIGITAL',
  })

  const combinedWastageComparisonHook = useCombinedWastage({
    ...wastageComparisonHookProps,
    enabled: wastageVariant === 'COMBINED',
  })

  const creativeWastageComparisonHook = useCreativeWastage({
    ...wastageComparisonHookProps,
    enabled: true,
  })

  const totalCreativeWastageComparisonHook = useTotalCreativeWastage({
    ...wastageComparisonHookProps,
    enabled: true,
  })

  useEffect(() => {
    const supportedComparisonTypes = supportedComparisonTypeMapping[wastageSegmentation]
    if( comparisonType && supportedComparisonTypes && !supportedComparisonTypes.includes(comparisonType) ){
      setComparisonType(supportedComparisonTypes[0])
    }
  }, [comparisonType, wastageSegmentation])

  const supportedComparisonTypes = supportedComparisonTypeMapping[wastageSegmentation] || []

  const supportsComparison = !!supportedComparisonTypes.length

  const {
    spendDatasets,
    // derivedTotalDataset,
    creativeQualityScoreDataset,
    wastagePercentageDataset,
    derivedPercentageSplit,
    ...comparisonDatasets
  } = useMemo(() => {
    const spendDatasets = getSpendDatasets({
      variant: wastageVariant,
      segmentation: wastageSegmentation,
      combinedWastage: combinedWastageHook.data,
      digitalWastage: digitalWastageHook.data,
      creativeWastage: creativeWastageHook.data,
    })

    const efficientSpendMultiDataset = spendDatasets.find( d => d.key === 'efficientSpend') || null
    const wastedSpendMultiDataset = spendDatasets.find( d => d.key === 'wastedSpend') || null

    const efficientSpendDataset = get(efficientSpendMultiDataset, 'data', null)
    const wastedSpendDataset = get(wastedSpendMultiDataset, 'data', null)

    const derivedTotalDataset = getDerivedTotalDataset({
      efficientSpendDataset: efficientSpendMultiDataset && efficientSpendMultiDataset.data,
      wastedSpendDataset: wastedSpendMultiDataset && wastedSpendMultiDataset.data,
      variant: wastageVariant,
    })

    const totalValues: Record<SpendDataset, number | null> = {
      efficientSpend: (
        efficientSpendDataset && getDatasetTotal(efficientSpendDataset)
      ),
      wastedSpend: (
        wastedSpendDataset && getDatasetTotal(wastedSpendDataset)
      ),
    }

    let derivedPercentageSplit: Record<SpendDataset, number> | null = null
    if( totalValues.efficientSpend !== null && totalValues.wastedSpend !== null ){
      const overallTotal = totalValues.efficientSpend + totalValues.wastedSpend
      derivedPercentageSplit = {
        efficientSpend: getPercentage(totalValues.efficientSpend, overallTotal),
        wastedSpend: getPercentage(totalValues.wastedSpend, overallTotal)
      }
    }

    const creativeQualityScoreDataset = getCreativeQualityScoreDataset(
      creativeWastageHook.data,
      wastageSegmentation,
    )

    const wastagePercentageDataset = getWastagePercentageDataset({
      variant: wastageVariant,
      segmentation: wastageSegmentation,
      combinedWastage: combinedWastageHook.data,
      digitalWastage: digitalWastageHook.data,
      creativeWastage: creativeWastageHook.data,
    })

    const spendComparisonDatasets = getSpendDatasets({
      variant: wastageVariant,
      segmentation: wastageSegmentation,
      combinedWastage: combinedWastageComparisonHook.data,
      digitalWastage: digitalWastageComparisonHook.data,
      creativeWastage: creativeWastageComparisonHook.data,
    })

    const creativeQualityScoreComparisonDataset = getCreativeQualityScoreDataset(
      creativeWastageComparisonHook.data,
      wastageSegmentation,
    )

    const wastagePercentageComparisonDataset = getWastagePercentageDataset({
      variant: wastageVariant,
      segmentation: wastageSegmentation,
      combinedWastage: combinedWastageComparisonHook.data,
      digitalWastage: digitalWastageComparisonHook.data,
      creativeWastage: creativeWastageComparisonHook.data,
    })

    return {
      spendDatasets,
      derivedTotalDataset,
      creativeQualityScoreDataset,
      wastagePercentageDataset,
      // Comparisons
      spendComparisonDatasets,
      creativeQualityScoreComparisonDataset,
      wastagePercentageComparisonDataset,
      // Derived split
      derivedPercentageSplit,
    }
  }, [
    wastageVariant, wastageSegmentation,
    combinedWastageHook.data, digitalWastageHook.data, creativeWastageHook.data,
    combinedWastageComparisonHook.data, digitalWastageComparisonHook.data,
    creativeWastageComparisonHook.data,
  ])

  const variantHookValues: Record<WastageVariant, UseQueryResult> = {
    COMBINED: combinedWastageHook,
    DIGITAL: digitalWastageHook,
    CREATIVE: creativeWastageHook,
  }

  const variantComparisonHookValues: Record<WastageVariant, UseQueryResult> = {
    COMBINED: combinedWastageComparisonHook,
    DIGITAL: digitalWastageComparisonHook,
    CREATIVE: creativeWastageComparisonHook,
  }

  const enabledHookConfigs: [boolean, UseQueryResult][] = [
    [cqsToggle[0], creativeWastageHook],
    [wastageSpendToggle[0], variantHookValues[wastageVariant]],
    [wastagePercentageToggle[0], variantHookValues[wastageVariant]]
  ]

  const enabledComparisonHookConfigs: [boolean, UseQueryResult][] = [
    [cqsToggle[0], creativeWastageComparisonHook],
    [wastageSpendToggle[0], variantComparisonHookValues[wastageVariant]],
    [wastagePercentageToggle[0], variantComparisonHookValues[wastageVariant]]
  ]

  const isLoading = some(enabledHookConfigs, ([required, hookValue]) => (
    required && isQueryHookLoading(hookValue)
  ))

  const isLoadingComparisons = supportsComparison && some(enabledComparisonHookConfigs, ([required, hookValue]) => (
    required && isQueryHookLoading(hookValue)
  ))

  const isError = some(enabledHookConfigs, ([required, hookValue]) => (
    required && isQueryHookError(hookValue)
  ))

  const isComparisonError = some(enabledComparisonHookConfigs, ([required, hookValue]) => (
    required && isQueryHookError(hookValue)
  ))

  const isLoadingTable = isLoading || totalCreativeWastageHook.isLoading

  const isLoadingTableComparisons = supportsComparison && (
    isLoadingComparisons || totalCreativeWastageComparisonHook.isLoading
  )

  const classes = useStyles()

  const datasetColorScheme = datasetColorSchemesMapping[wastageVariant]

  const svgRef = useRef<SVGSVGElement | null>(null)

  return (
    <Box p={3}>
      <Typography variant='h3' className={classes.title}>
        Wastage Trend Reports
      </Typography>

      <WastageTrendTabs
        value={wastageVariant}
        onChange={setWastageVariant} />

      <Typography className={classes.text}>
        { variantDescriptions[wastageVariant] }
      </Typography>

      <Box display='flex' alignItems='center' justifyContent='space-between' mt={3}>
        <Box display='flex' alignItems='center'>
          <ControlBox
            mr={5}
            label={
              <Typography variant='h5'>Filter By</Typography>
            }>
            <MonthRangeSelect
              ButtonPopoverProps={{
                size: 'small',
                color: 'secondary',
              }}
              minDate={minDate}
              maxDate={maxDate}
              value={dateRange}
              onChange={(value): void => {
                setDateRange([
                  value[0] && startOfMonth(value[0]),
                  value[1] && endOfMonth(value[1]),
                ])
              }} />

            <MultiMarketSelect
              ButtonPopoverProps={{
                size: 'small',
                color: 'secondary',
                buttonContent: 'Markets',
              }}
              value={orgUnitIdFilter}
              options={marketOptions}
              onChange={setOrgUnitIdFilter} />

            <MultiProviderSelect
              ButtonPopoverProps={{
                size: 'small',
                color: 'secondary',
                buttonContent: 'Vendors',
              }}
              providerLabel='Vendor'
              value={providerFilter}
              options={providerOptionsByWastageVariant[wastageVariant]}
              onChange={setProviderFilter} />
          </ControlBox>

          <ControlBox
            mr={5}
            label={
              <Typography variant='h5'>Slice By</Typography>
            }>
            <WastageSegmentationSelect
              TriggerProps={{
                size: 'small',
              }}
              value={wastageSegmentation}
              onChange={setWastageSegmentation} />
          </ControlBox>

          <ControlBox
            mr={5}
            label={
              <Typography variant='h5'>Overlay</Typography>
            }>
            <StateHookCheckbox
              label='Creative Quality Score'
              hookValue={cqsToggle} />

            <StateHookCheckbox
              label='Wastage %'
              hookValue={wastagePercentageToggle} />

            <StateHookCheckbox
              label='Wastage Spend'
              hookValue={wastageSpendToggle} />
          </ControlBox>
        </Box>

        { derivedPercentageSplit && (
          <Box display='flex' alignItems='center' mr={1.5}>
            <Typography className={classes.spacedRight} variant='h5'>
              <span
                className={classes.derivedTotalDatasetText}
                style={{
                  color: datasetColorScheme.wastedSpend,
                  marginRight: 4,
                }}>
                Wastage
              </span>
              <span
                className={classes.disabledText}>
                vs.
              </span>
              <span
                className={classes.derivedTotalDatasetText}
                style={{
                  color: datasetColorScheme.efficientSpend,
                  marginLeft: 4,
                }}>
                Efficient Spend
              </span>
            </Typography>
            <PercentageDonut
              width={72}
              height={72}
              donutThickness={0.4}
              padAngle={0.035}
              fontSize={14}
              value={derivedPercentageSplit.wastedSpend}
              color={datasetColorScheme.wastedSpend}
              textColor={datasetColorScheme.wastedSpend}
              trackColor={datasetColorScheme.efficientSpend}
            />
          </Box>
        )}
      </Box>

      <Box display='flex' alignItems='center' mt={4} px={1}>
        { dateRange[0] && dateRange[1] && (
          <Box display='flex' alignItems='center'>
            <Typography variant='h5'>
              { formatMonth(dateRange[0]) } to { formatMonth(dateRange[1]) }
            </Typography>
            <span className={classes.separator}>|</span>
            { getMarketFilterLabel(orgUnitIdFilter) }
            <span className={classes.separator}>|</span>
            { getVendorFilterLabel(providerFilter, providerOptionsByWastageVariant[wastageVariant]) }
          </Box>
        )}

        { !isError && !isLoading && userCanExport && (
          <Box marginLeft='auto'>
            <RoundedPlainTextButton
              size='small'
              color='secondary'
              variant='contained'
              startIcon={<CloudDownload />}
              onClick={(): void => {
                if( svgRef.current ){
                  downloadSVG(
                    svgRef.current,
                    'wastage-trend-reports.svg',
                    {
                      margin: {
                        top: 30,
                        bottom: 10,
                        left: 10,
                        right: 20,
                      },
                      attributes: {
                        overflow: 'visible',
                      }
                    }
                  ).then(console.log)
                }
              }}>
              Export Chart
            </RoundedPlainTextButton>
          </Box>
        )}
      </Box>

      <Box mt={2} p={1}>
        <Card elevation={4}>
          <Box mt={5} height={600}>
            { isError ? (
              <Box
                display='flex'
                height='100%'
                alignItems='center'
                alignSelf='center'
                justifyContent='center'
                fontSize={22}
                fontWeight={600}>
                <ErrorOutline
                  color='error'
                  style={{marginRight: 8}} />
                An error occurred and wastage trend reports could not be loaded
              </Box>
            ) : isLoading ? (
              <Loader preset='fullsize' />
            ) : (
              <ParentSize>
                { ({ width, height }): JSX.Element => (
                  <WastageTrendChart
                    svgRef={svgRef}
                    width={width}
                    height={height}
                    variant={wastageVariant}
                    segmentation={wastageSegmentation}
                    spendDatasets={spendDatasets}
                    wastagePercentageDataset={wastagePercentageDataset}
                    creativeQualityScoreDataset={creativeQualityScoreDataset}
                    showCreativeQualityScore={cqsToggle[0]}
                    showWastagePercentage={wastagePercentageToggle[0]}
                    showWastageSpend={wastageSpendToggle[0]} />
                )}
              </ParentSize>
            )}
          </Box>
        </Card>
      </Box>

      { !isError && !isLoading && (
        <>
          <Box mt={6} px={1} display='flex' alignItems='center'>
            <Typography variant='h5' className={classes.spacedRight}>
              Comparison Type
            </Typography>

            <RoundedPlainTextButtonMenu
              TriggerProps={{
                variant: 'contained',
                size: 'small',
                color: 'secondary',
                endIcon: supportsComparison && <ArrowDropDown />,
              }}
              value={comparisonType}
              label={
                supportsComparison && comparisonType && deslugify(comparisonType) || (
                  !supportsComparison ?
                    'N / A' :
                    'Select Type'
                )
              }
              disabled={!supportsComparison}
              options={
                supportedComparisonTypes.map( value => ({
                  value,
                  label: deslugify(value)
                }))
              }
              onChange={(e, value: WastageComparisonType): void => {
                setComparisonType(value)
              }} />

            { isComparisonError && (
              <Box
                display='flex'
                alignItems='center'
                alignSelf='center'
                justifyContent='center'
                ml={3}
                fontSize={14}
                fontWeight={600}>
                <ErrorOutline
                  color='error'
                  style={{marginRight: 4}} />
                An error occurred and comparisons could not be loaded
              </Box>
            )}

            { userCanExport && (
              <Box marginLeft='auto'>
                <RoundedPlainTextButton
                  size='small'
                  color='secondary'
                  variant='contained'
                  disabled={csvExportHook.isLoading}
                  startIcon={
                    csvExportHook.isLoading ?
                      <Loader size='1em' color='inherit' /> :
                      <CloudDownload />
                  }
                  onClick={(): void => {
                    csvExportHook.mutate({
                      org_unit_id: org_unit_id,
                      variant: wastageVariant,
                      segment_type: wastageSegmentation,
                      start: dateRange[0],
                      end: dateRange[1],
                      comparison_type: comparisonType,
                      org_unit_ids: orgUnitIdFilter,
                      providers: providerFilter,
                    })
                  }}>
                  Export CSV
                </RoundedPlainTextButton>
              </Box>
            )}
          </Box>

          <Box mt={2} p={1}>
            <Card elevation={4}>
              { isLoadingTable ? (
                <Loader minHeight='10rem' preset='fullsize' />
              ) : (
                <WastageTrendTable
                  variant={wastageVariant}
                  segmentation={wastageSegmentation}
                  spendDatasets={spendDatasets}
                  wastagePercentageDataset={wastagePercentageDataset}
                  creativeQualityScoreDataset={creativeQualityScoreDataset}
                  supportsComparison={supportsComparison}
                  comparisonType={comparisonType}
                  isLoadingComparisons={isLoadingTableComparisons}
                  totalCreativeWastageResponse={totalCreativeWastageHook.data}
                  totalCreativeWastageComparisonResponse={totalCreativeWastageComparisonHook.data}
                  {...comparisonDatasets} />
              )}
            </Card>
          </Box>
        </>
      )}

    </Box>
  )
}
